import BaseApi from "../BaseApi"
import actionTypes, { IApplicationResponse } from "../baseTypes"
import {
	ILanguage,
	ITranslate,
	ITranslateWithUrl,
	ITranslateValuesByTranslator,
	ITranslatedValue,
	CheckRefreshLanguagesResponse,
} from "../../domain/types"

export default class TranslateApi extends BaseApi {
	//3 минуты
	private static longTimeout = 180 * 1000

	getLanguages = (): Promise<IApplicationResponse<ILanguage[]>> =>
		this.sendQuery<ILanguage[]>(
			"/api/translate/lang",
			null,
			actionTypes.get,
			true
		)

	getTranslates = (data: {
		catalogId: number | null
		translateSource: number
		targetLanguageId: number
		search: string
		isSemantic: boolean
		semanticSearch: string
		isEmpty: boolean
	}): Promise<IApplicationResponse<ITranslate[]>> =>
		this.sendQuery<ITranslate[]>(
			"/api/translate",
			data,
			actionTypes.get,
			true,
			TranslateApi.longTimeout
		)

	getTranslatesWithUrl = (data: {
		catalogId: number
		targetLanguageId: number
		websiteId: number
		isEmpty: boolean
		search: string
	}): Promise<IApplicationResponse<ITranslateWithUrl[]>> =>
		this.sendQuery<ITranslateWithUrl[]>(
			"/api/translate/with-urls",
			data,
			actionTypes.get,
			true,
			TranslateApi.longTimeout
		)

	// Перевод значений через переводчик.
	translateValuesByTranslator = (data: {
		sourceLanguageId: number
		targetLanguageId: number
		values: string[]
	}): Promise<IApplicationResponse<ITranslateValuesByTranslator[]>> =>
		this.sendQuery<ITranslateValuesByTranslator[]>(
			"/api/translate/translate-values-by-translator",
			data,
			actionTypes.post,
			true,
			TranslateApi.longTimeout
		)

	// Сохранение переводов.
	saveTranslatedValues = (data: {
		targetLanguageId: number
		translator: string
		translates: ITranslatedValue[]
	}): Promise<IApplicationResponse<void[]>> =>
		this.sendQuery<void[]>(
			"/api/translate/save-translated-values",
			data,
			actionTypes.put,
			true,
			TranslateApi.longTimeout
		)

	updateTranslates = (data: {
		translateId: number
		translate: string
		translateSource: number
		sourceId: string
		languageId: number
	}): Promise<IApplicationResponse<string>> =>
		this.sendQuery<string>("/api/translate", data, actionTypes.put, true)

	generateNewTranslates = (): Promise<IApplicationResponse<void>> =>
		this.sendQuery<void>(
			"/api/translate/gen-new",
			null,
			actionTypes.put,
			true
		)

	addNewLanguage = (data: {
		language: string
	}): Promise<IApplicationResponse<number>> =>
		this.sendQuery<number>(
			"/api/translate/add-new-language",
			data,
			actionTypes.post,
			true
		)

	removeLanguage = (data: {
		languageId: number
	}): Promise<IApplicationResponse<void>> =>
		this.sendQuery<void>(
			"/api/translate/remove-language",
			data,
			actionTypes.delete,
			true
		)

	updateSourcesFromTranslates = (data: {
		languageId: number
		types: number[]
	}): Promise<IApplicationResponse<string>> =>
		this.sendQuery<string>(
			"/api/translate/update-sources-from-translates",
			data,
			actionTypes.put,
			true,
			120 * 1000
		)

	cleanUpTranslations = (): Promise<IApplicationResponse<null>> =>
		this.sendQuery(
			"/api/translate/clean-up-translations",
			null,
			actionTypes.get,
			true
		)

	checkRefreshLanguages = (): Promise<IApplicationResponse<CheckRefreshLanguagesResponse>> =>
		this.sendQuery<CheckRefreshLanguagesResponse>(
			"/api/translate/check-refresh-languages",
			null,
			actionTypes.get,
			true,
			120 * 1000
		)

	refreshLanguages = (): Promise<IApplicationResponse<null>> =>
		this.sendQuery<null>(
			"/api/translate/refresh-languages",
			null,
			actionTypes.post,
			true,
			120 * 1000
		)
}
