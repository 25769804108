import Api from "../../../../api"
import { CheckRefreshLanguagesResponse } from "../../../../domain/types"
import { ModalStatus } from "../../../../library/modal/AdResultModal"
import {
	createAutogenApiThunk,
	createAutogenNoArgsApiThunk,
	executeQuery,
} from "../../../createApiThunkV2"
import { createAsyncThunk } from "@reduxjs/toolkit"
import { actions as loaderActions } from "../../informer"
import { actions } from "./index"

export class TranslateThunks {
	static generateNewTranslates = createAutogenNoArgsApiThunk({
		typePrefix: "translate-page/gen-new-translate",
		apiCall: Api.translate.generateNewTranslates,
		errorMessage: "Не удалось сгенерировать новые переводы",
		showLoader: true,
		showSuccess: true,
		successMessage: "Переводы успешно сгенерированы",
	})

	static updateSourcesFromTranslates = createAutogenApiThunk({
		typePrefix: "translate-page/update-sources-from-translates",
		apiCall: Api.translate.updateSourcesFromTranslates,
		showLoader: true,
		showSuccess: true,
		successMessage: "Источники успешно обновлены",
		errorMessage: "Не удалось обновить источники",
	})

	static generateShortnames = createAutogenApiThunk({
		typePrefix: "translate-page/generate-shortnames",
		apiCall: Api.products.generateShortNames,
		showLoader: true,
		showSuccess: true,
		successMessage: "Короткие названия успешно сгенерированы",
		errorMessage: "Не удалось сгенерировать короткие названия",
	})

	static uploadTranslate = createAutogenApiThunk({
		typePrefix: "translate-page/upload-translate",
		apiCall: Api.files.uploadTranslate,
		showSuccess: true,
		showLoader: true,
		successMessage: "Переводы успешно загружены",
		errorMessage: "Не удалось загрузить переводы",
	})

	static getCatalogInfoAsZip = createAutogenApiThunk<
		{ catalogId: number; websiteId: number; languageId: number },
		void
	>({
		typePrefix: "translate-page/get-catalog-info-as-zip",
		apiCall: Api.files.getCatalogInfoAsZip,
		showLoader: true,
		errorMessage: "Не удалось скачать архив с информацией о каталоге",
	})

	static refreshLanguages = createAsyncThunk(
		"upload-data-to-site/hide-by-products",
		async (args: any, thunkAPI) => {
			try {
				if (args.needCheck) {
					const options = {
						typePrefix: "translate-page/check-refresh-languages",
						apiCall: Api.translate.checkRefreshLanguages,
						errorMessage: "Не удалось проверить доступность обновления языков",
						showLoader: true,
						showSuccess: false,

					};
					const response = await executeQuery(null, options, thunkAPI.dispatch)

					const isCanRefresh = (response && response.success && response.data && response.data.result) ?? false;

					if (!response || !response.success)
						return;

					if (!isCanRefresh) {
						thunkAPI.dispatch(actions.setShowRefreshLanguagesModal(true))

						return;
					}
				}

				const options = {
					typePrefix: "translate-page/refresh-languages",
					apiCall: Api.translate.refreshLanguages,
					errorMessage: "Не удалось обновить набор языков у переводов",
					showLoader: true,
					showSuccess: true,
					successMessage: "Список языков успешно обновлен",
				};
				const response = await executeQuery(null, options, thunkAPI.dispatch)

				if (!response?.success) {
					thunkAPI.dispatch(
						loaderActions.showLoader({
							status: ModalStatus.error,
							text: "Не удалось обновить набор языков у переводов",
						})
					)
					return;
				}

				thunkAPI.dispatch(actions.setShowRefreshLanguagesModal(false))

				return;
			}
			catch (e: any) {
				thunkAPI.dispatch(
					loaderActions.showLoader({
						status: ModalStatus.error,
						text: "Не удалось исправить JSON у переводов",
					})
				)
			}
			return;
		},
	)
}
