import React, { useEffect, useRef, useState } from "react"
import RouteProvider from "../../utils/RouteProvider"
import Protector from "../../library/smartComponents/authorization/Protector"
import "./previewPdf.scss"
import { useDispatch, useSelector } from "react-redux"
import { ReactComponent as ImgAvtodeloLogoPdf } from "../../assets/logos/img-avtodelo-logo-pdf.svg"
import { ReactComponent as ImgBackgroundLeftPdf } from "../../assets/pdf/background-left.svg"
import { ReactComponent as ImgBackgroundRightPdf } from "../../assets/pdf/background-right.svg"
import { ReactComponent as ImgBackgroundLeftPrintPdf } from "../../assets/pdf/background-print-left.svg"
import { ReactComponent as ImgBackgroundRightPrintPdf } from "../../assets/pdf/background-print-right.svg"
import { ReactComponent as ImgSiteIcon } from "../../assets/pdf/site-icon.svg"
import { LanguageSelectors } from "../../redux/reducers/languages/languageSelectors"
import { GuidePrroductGroupWithProductThunks } from "../../redux/reducers/pages/guidePage/thunks"
import { guideSelectors } from "../../redux/reducers/pages/guidePage/guideSelectors"
import { GuideGroupImageForPdf, IGuideAttributeForPdf, IGuideCatalogForPdf, IGuideGroupForPdf, IGuideGroupToolsetForPdf, IGuidePictogramForPdf, IGuideProductToolsetForPdf, IGuideSeriesForPdf, IGuideSignForPdf } from "../../domain/models/guide/GuideForPdf"
import Constants from "../../domain/Constants"
import { Guid } from "guid-typescript"
import { renderToString } from 'react-dom/server';
import { SvgIcon } from "../../library/icons/SvgIcon"
import QRCode from "react-qr-code";
import { savePDF } from "@progress/kendo-react-pdf"
import SimpleSelect, { IOptionType } from "../common/basic/selectors/SimpleSelect"
import NullableSelect from "../common/basic/selectors/NullableSelect"

import { actions } from "../../redux/reducers/pages/guidePage/index"
import AdDefaultInput from "../../library/inputs/AdDefaultInput"
import CatalogSelector from "../../library/smartComponents/catalogSelector/CatalogSelector"
import { CatalogGroup, ILanguage, IWebsite } from "../../domain/types"
import WebsiteSelector from "../../library/smartComponents/websiteSelector/WebsiteSelector"
import ToOptionProvider from "../../utils/ToOptionProvider"
import { CatalogSelectors } from "../../redux/reducers/catalogs/catalogSelectors"
import { WebsiteSelectors } from "../../redux/reducers/webSites/websiteSelectors"

const staticServer = process.env.REACT_APP_STATIC_SERVER_API
const maxHeightContent = 956;
const errors: string[] = [];

interface IDataProductCsv {
	id: string;
	page: number
}

const dataProductsCsv: IDataProductCsv[] = [];

enum TypeTemplate {
	DEFAULT,
	TOOLSET_SMALL,
	TOOLSET_BIG
}

enum TypeView {
	WEB = 0,
	PRINT = 1,
}

const optionsTypeViews: IOptionType[] = [{
	label: "Web вариант",
	value: TypeView.WEB
},
{
	label: "Печатный вариант",
	value: TypeView.PRINT
}]

const takePage = 30;
const maxWidthImage = 233;
const maxHeightImage = 220;
const excludeGroupsForAttributes: string[] = ["43020", "43104", "43301", "43302", "43408", "43422"]
const excludeGroupAttributes: number[] = [7, 57, 21]

const calculateHaight = (ratio: number, maxWidth = maxWidthImage, maxHeight = maxHeightImage) => {
	if (!ratio || ratio == 0)
		return 0;

	const ratioMax = maxWidth / maxHeight;
	if (ratio <= ratioMax)
		return maxHeight;

	return maxWidth / ratio;
}


const toOption = (u: IOptionType): IOptionType => {
	return u;
}

function getDivChild(element: JSX.Element) {
	const div = document.createElement("div") as any;
	div.innerHTML = renderToString(element);

	return div.firstChild;
}

function getDivChildren(element: JSX.Element): any[] {
	const div = document.createElement("div") as any;
	div.innerHTML = renderToString(element);
	const arr: any[] = [];
	for (const elem of div.childNodes) {
		arr.push(elem)
	}
	return arr;
}

const ExportProductCSV = () => {
	const downloadCSV = () => {
		const csvString = [
			...dataProductsCsv.map(item => [item.id, item.page])
		]
			.map(row => row.join(";"))
			.join("\n");

		const blob = new Blob([csvString], { type: 'text/csv' });

		const url = URL.createObjectURL(blob);
		const link = document.createElement('a');
		link.href = url;
		link.download = 'download-render-pdf.csv';
		document.body.appendChild(link);
		link.click();
		document.body.removeChild(link);
		URL.revokeObjectURL(url);
	};

	return <button className="u-button large primary" onClick={downloadCSV}>Export CSV</button>;
};

export default function PreviewPdf() {
	const dispatch = useDispatch()
	const data = useSelector(guideSelectors.getData)
	const parentCategories = useSelector(guideSelectors.getParentCategories)
	const pictograms = useSelector(guideSelectors.getPictograms)
	const attributes = useSelector(guideSelectors.getAttributes)
	const series = useSelector(guideSelectors.getSeries)
	const signs = useSelector(guideSelectors.getSigns)

	const getOptions = useSelector(guideSelectors.getOptions)
	const getSelectedGroup = useSelector(guideSelectors.getSelectedGroup)
	const languages = useSelector(LanguageSelectors.getLanguages)
	const selectedCatalog = useSelector(CatalogSelectors.getSelected)
	const selectedWebsite = useSelector(WebsiteSelectors.getSelected)
	const targetRef = useRef(null);

	const [startNumeration, setStartNumeration] = useState<number>(1)
	const [isCalculated, setIsCalculated] = useState<boolean>(false)
	const [countPages, setCountPages] = useState<number>(0)
	const [pageOptions, setPageOptions] = useState<IOptionType[]>([]);
	const [selectedPage, setSelectedPage] = useState<IOptionType | null>(null);
	const [selectedLanguage, setSelectedLanguage] = useState<ILanguage>(languages[0]);
	const [selectedTypeView, setSelectedTypeView] = useState<IOptionType>(optionsTypeViews[0]);

	useEffect(() => {
		if (!selectedLanguage || !selectedCatalog || selectedCatalog.id < 1 || !selectedWebsite || selectedWebsite.id < 1)
			return;

		dispatch(actions.setSelectedGroup(undefined))
		setIsCalculated(false);
		setCountPages(0);
		setSelectedPage(null);

		dispatch(
			GuidePrroductGroupWithProductThunks.getGuide({
				languageId: selectedLanguage.id,
				catalogId: selectedCatalog.id,
				websiteId: selectedWebsite.id,
			})
		)
	}, [
		selectedLanguage.id,
		selectedCatalog,
		selectedWebsite
	])

	useEffect(() => {
		setIsCalculated(false);
		setCountPages(0);
		const lastSelectedPage = selectedPage;
		setSelectedPage(null);

		if (!getSelectedGroup) {
			removePages();
			return;
		}

		if (!lastSelectedPage)
			createdPages(getSelectedGroup.value);


	}, [getSelectedGroup])

	useEffect(() => {
		if (!getSelectedGroup) {
			removePages();
			return;
		}

		if (isCalculated && !selectedPage)
			return;

		createdPages(getSelectedGroup.value);

	}, [startNumeration, selectedTypeView, selectedPage])

	useEffect(() => {
		const arr: IOptionType[] = [];
		let maxValue = Math.floor(countPages / takePage);
		if (countPages % takePage)
			maxValue++;

		for (let i = 0; i < maxValue; i++) {
			arr.push({
				label: `Страницы: ${(takePage * i + 1)}-${takePage * (i + 1)}`,
				value: i
			} as IOptionType)
		}
		setPageOptions(arr)

	}, [countPages])

	const removePages = () => {
		const book = document.querySelector(".render-pdf .book");
		book?.querySelectorAll(".page").forEach(u => u.remove())

		errors.length = 0;
		const blockErrors = document.querySelectorAll(".render-pdf .errors div");
		blockErrors.forEach(u => u.remove())
	}

	let _countPages = 0;

	const createdPages = (catalogId: number) => {
		const book = document.querySelector(".render-pdf .book");
		book?.querySelectorAll(".page").forEach(u => u.remove())

		errors.length = 0;
		const blockErrors = document.querySelectorAll(".render-pdf .errors div");
		blockErrors.forEach(u => u.remove())

		const category = data.find(u => u && u.parentId == catalogId);
		const catalogParent = parentCategories.find(u => u.id == catalogId);

		if (!category || !catalogParent || (isCalculated && !selectedPage))
			return;

		const startPage = selectedPage ? selectedPage.value * takePage + 1 : null;
		const endPage = selectedPage ? takePage * (selectedPage.value + 1) - 1 : null;

		const catalogInfo = category as IGuideCatalogForPdf;

		let isNewPage = true;
		let numberPage: number = startNumeration;
		let typeTemplate: TypeTemplate = TypeTemplate.DEFAULT;

		let content: any = undefined;

		const renderPage = (catalogName: string) => {

			if (endPage != null && _countPages > endPage) {
				return null;
			}

			if (startPage != null && _countPages < startPage) {
				errors.length = 0;
				book?.querySelectorAll(".page").forEach(u => u.remove())
			}

			_countPages++;

			const indexCategory = getOptions.findIndex(u => u.value == getSelectedGroup?.value)
			const page = getDivChild(createPage(numberPage,
				catalogName,
				selectedTypeView.value,
				indexCategory / getOptions.length,
				catalogParent,
				selectedWebsite))

			book?.append(page);
			const localContent = page.querySelector(".content");
			isNewPage = false;

			numberPage++;

			return localContent;
		}

		const renderToolsetInfoPages = (group: IGuideGroupForPdf) => {
			if (!group.isToolset || !group.toolsetProducts || group.attributes[Constants.AttributeComplexId]) {
				return (<></>);
			}

			const defaultRow = (rowId: string, name: string | undefined | null = undefined, showName = true) => {
				return (
					<div className="content-row" id={rowId}>
						<div className="content-toolset">
							<div className="content-toolset-item" key={Guid.create().toString()}>
								{showName && <div className="title">{name}</div>}
								<div className="table"></div>
							</div>
						</div>
					</div>)
			}

			let createRow = true;
			let rowId = "";
			let rowGroup: any = null;
			let rowGroupTable: any = null;
			let showName = true;

			const groupKeys = Object.keys(group.toolsetProducts);

			for (let keyIndex = 0; keyIndex < groupKeys.length; keyIndex++) {

				const groupKey = groupKeys[keyIndex];

				const name = pictograms.find(t => t.id.toString() === groupKey)?.name;
				showName = true;
				createRow = true;

				for (let i = 0; i < group.toolsetProducts[groupKey].length; i++) {
					if (isNewPage || !content) {
						content = renderPage(group.catalog?.name);
						if (!content)
							return (<></>);

						createRow = true;
					}

					if (createRow) {
						rowId = Guid.create().toString();
						rowGroup = getDivChild(defaultRow(rowId, name, showName));
						content.append(rowGroup)
						rowGroupTable = rowGroup.querySelector(".table")
						createRow = false;
					}

					if (isNewPage) {
						isNewPage = false;
						if (!showName)
							showName = true;
					}

					const rowItems = getDivChildren(createProductToolsetTableItem(attributes, signs, group.toolsetProducts[groupKey][i]))
					for (let f = 0; f < rowItems.length; f++) {
						rowGroupTable.append(rowItems[f])
					}
					if (content.scrollHeight > maxHeightContent) {
						isNewPage = true;

						if (i == 0) {
							showName = true;
							keyIndex--;
							rowGroup.remove();
							break;
						}
						else {
							i--;
							showName = false;
							for (let f = 0; f < rowItems.length; f++) {
								rowGroupTable.removeChild(rowItems[f])
							}
						}

					}
				}
			}
		}

		for (let i = 0; i < category.groups.length; i++) {
			if (!catalogInfo) {
				errors.push(`Не установлен каталог для группы: "${category.groups[i].name}" (${category.groups[i].id})`)
			}

			if (category.groups[i].isToolset && Object.keys(category.groups[i].toolsetProducts).length > 0) {
				if (Object.values(category.groups[i].toolsetProducts).reduce((count, el) => count + el.length, 0) >= 30) {
					typeTemplate = TypeTemplate.TOOLSET_BIG;
					isNewPage = true;
				}
				else {
					typeTemplate = TypeTemplate.TOOLSET_SMALL;
				}
			}
			else {
				typeTemplate = TypeTemplate.DEFAULT;
			}

			if (isNewPage)
				content = renderPage(category.groups[i].catalog?.name);

			if (!content)
				break;

			const row = getDivChild(createRow(typeTemplate,
				selectedTypeView.value,
				attributes,
				signs,
				pictograms,
				series,
				category.groups[i],
				isCalculated,
				selectedWebsite))
			content.append(row);

			if (typeTemplate != TypeTemplate.TOOLSET_BIG) {

				if (content.scrollHeight > maxHeightContent) {
					row.remove();
					isNewPage = true;
					i--;
				}
			}
			else {
				isNewPage = true;
				renderToolsetInfoPages(category.groups[i]);
			}
		}

		renderErrors();
		if (!isCalculated) {
			dataProductsCsv.length = 0;

			book?.querySelectorAll(".render-pdf .book .page").forEach(x => {
				const pageDiv = x.querySelector(".footer .number");
				const pageNum = Number(pageDiv?.getAttribute("data-number"));

				x.querySelectorAll("[data-product-id]").forEach(u => {
					const value = u.getAttribute("data-product-id");
					dataProductsCsv.push({ id: value, page: pageNum } as IDataProductCsv)
				})
			});

			setCountPages(_countPages);
			setIsCalculated(true);
		}
		else {
			book?.querySelectorAll("img[data-src]").forEach(x => {
				x.setAttribute("src", x.getAttribute("data-src")!)
				x.removeAttribute("data-src")
			})
		}
	}

	const onExportWithMethod = (targetRef) => {
		const page = document.querySelector('.page') as any;

		let size: [number | string, number | string] = [801, 1133];

		if (page)
			size = [page.offsetWidth, page.offsetHeight];

		savePDF(targetRef.current, { paperSize: size, margin: 0 });
	};

	const setLocalLanguage = (languageId: number) => {
		const language = languages.find((x) => x.id === languageId)
		if (!language) return
		dispatch(actions.setSelectedGroup(undefined))
		setSelectedLanguage(language);
	}

	return (
		<Protector availableRoles={RouteProvider.generatePreviewPdf.roles!}>
			<div className="render-pdf">
				<div className="render-pdf-header">
					<div className="render-pdf-header-row1">
						<button className="u-button large primary" onClick={() => onExportWithMethod(targetRef)}>Скачать PDF</button>
						{ExportProductCSV()}
						<NullableSelect
							value={getSelectedGroup}
							options={getOptions}
							placeholder={"Выберите категорию"}
							onChange={(u) => dispatch(actions.setSelectedGroup(u as number))}
							toOption={toOption}
							className={"selector"} />
						<div>Нумерация с:</div>
						<AdDefaultInput value={startNumeration.toString()}
							onChange={(e) => {
								if (isNaN(Number(e)))
									return;

								setStartNumeration(Number(e));
							}} />
						<SimpleSelect
							value={selectedTypeView}
							options={optionsTypeViews}
							onChange={(u) => {
								const typeView = optionsTypeViews.find(t => t.value == u);
								if (!typeView)
									return;
								setSelectedTypeView(typeView)
							}}
							toOption={toOption}
							className={"selector"}
						/>
						<NullableSelect
							value={selectedPage}
							options={pageOptions}
							onChange={(u: number | null | string) => {
								if (u == null)
									setSelectedPage(null)
								else {
									const item = pageOptions.find(f => f.value == u);
									if (item)
										setSelectedPage(item)
								}
							}}
							toOption={toOption}
							className={"selector"}
						/>
					</div>
					<div className="render-pdf-header-row2">
						<SimpleSelect
							value={selectedLanguage}
							options={languages}
							onChange={setLocalLanguage}
							toOption={ToOptionProvider.languageToOption}
							className={"selector"}
						/>
						<CatalogSelector catalogGroup={CatalogGroup.Printed} />
						<WebsiteSelector schema={undefined} />
					</div>
				</div>
				<div className="errors"></div>
				<div className={
					"book"
					+ (selectedTypeView.value == TypeView.PRINT ? " print" : "")
				} ref={targetRef}></div>
			</div>
		</Protector>
	)
}

const renderErrors = () => {
	const blockErrors = document.querySelector(".render-pdf .errors");

	errors.map(u => {
		const div = document.createElement("div");
		div.innerHTML = u;

		blockErrors?.append(div)
	})
}

function createPage(page: number, title: string, selectedType: TypeView, top: number, catalog: IGuideCatalogForPdf, website: IWebsite) {

	const isRight = selectedType == TypeView.PRINT ? page % 2 != 0 : page % 2 == 0;

	const background = () => {
		const className = "substrate";

		if (selectedType == TypeView.PRINT)
			return isRight ? <ImgBackgroundRightPrintPdf className={className} /> : <ImgBackgroundLeftPrintPdf className={className} />

		return isRight ? <ImgBackgroundRightPdf className={className} /> : <ImgBackgroundLeftPdf className={className} />
	}

	const createCategoryImage = () => {
		if (!catalog || !catalog.convertImageUrl)
			return <></>

		return <div className="category">
			<div>
				<img data-src={`${staticServer}/${catalog.convertImageUrl}?${Guid.create()}`}
					style={{ top: `${(top * 100)}%` }}
				/>
			</div>
		</div>;
	}

	return (<>
		<div className={"page " + (isRight ? "right" : "left")}>
			<div className="body">
				<div className="header">
					<div className="title">{title}</div>
					<div className="logo">
						<ImgAvtodeloLogoPdf />
					</div>
				</div>
				<div className="content"></div>
				<div className="footer">
					<div className="number" data-number={page}>{page}</div>
					<div className="site">
						<ImgSiteIcon />
						<div>{website.name}</div>
					</div>
				</div>
				{background()}
				{createCategoryImage()}
			</div>
		</div>
	</>)
}


function createRow(
	typeTemplate: TypeTemplate,
	selectedType: TypeView,
	attributes: IGuideAttributeForPdf[],
	signs: IGuideSignForPdf[],
	pictograms: IGuidePictogramForPdf[],
	series: IGuideSeriesForPdf[],
	group: IGuideGroupForPdf,
	isCalculated: boolean,
	website: IWebsite) {
	const angerImage = group.images.find(u => u.typeId == Constants.BaseImageType);
	const planImage = group.images.find(u => u.typeId == Constants.PlanImageType);
	const boxImage = group.images.find(u => u.typeId == Constants.BoxImageType);
	const packImage = group.images.find(u => u.typeId == Constants.PackImageType);
	const pinImages = pictograms.filter(t => group.pictograms.includes(t.id));
	const urlDefault = `https://${website.name}`

	const seriesImage = series.find(u => u.id == group.seriesId);
	const isSeriesImage = seriesImage && seriesImage.convertImageUrl

	if (!angerImage) {
		errors.push(`Не установлено основное изображение для группы: "${group.name}" (${group.id})`)
	}
	if (!group.description) {
		errors.push(`Не установлено описание для группы: "${group.name}" (${group.id})`)
	}

	return (<>
		<div className={"content-row"
			+ (typeTemplate === TypeTemplate.TOOLSET_BIG ? " toolset" : "")
			+ (isSeriesImage ? " is-series" : "")

		} id={Guid.create().toString()}>
			{typeTemplate === TypeTemplate.TOOLSET_BIG && <>
				{isSeriesImage && <img className="series" data-src={!isCalculated ? "" : `${staticServer}/${seriesImage.convertImageUrl}?${Guid.create()}`} />}
				<div className="content-row-image">
					{angerImage && <img data-src={!isCalculated ? "" : `${staticServer}/${(selectedType == TypeView.PRINT ? angerImage.convertImageUrl : angerImage.path)}?${Guid.create()}`}
						className="content-base-image"
						style={{
							aspectRatio: angerImage.ratio && angerImage.ratio > 0 ? angerImage.ratio.toString() : "auto"
						}} />}
				</div>
			</>}

			<div className="content-info">
				<div className="content-images">
					{typeTemplate !== TypeTemplate.TOOLSET_BIG && isSeriesImage && <div>
						<img className="series" data-src={!isCalculated ? "" : `${staticServer}/${seriesImage.convertImageUrl}?${Guid.create()}`} />
					</div>}

					{typeTemplate !== TypeTemplate.TOOLSET_BIG && angerImage && <img data-src={!isCalculated ? "" : `${staticServer}/${(selectedType == TypeView.PRINT ? angerImage.convertImageUrl : angerImage.path)}?${Guid.create()}`}
						className="anger"
						style={{
							aspectRatio: angerImage.ratio && angerImage.ratio > 0 ? angerImage.ratio.toString() : "auto",
							height: angerImage.ratio && angerImage.ratio ? `${calculateHaight(angerImage.ratio)}px` : "auto"
						}} />}

					{(typeTemplate === TypeTemplate.DEFAULT || typeTemplate === TypeTemplate.TOOLSET_BIG) && planImage && <img data-src={!isCalculated ? "" : `${staticServer}/${(selectedType == TypeView.PRINT ? planImage.convertImageUrl : planImage.path)}?${Guid.create()}`}
						className="plan"
						style={{
							aspectRatio: planImage.ratio && planImage.ratio > 0 ? planImage.ratio.toString() : "auto",
							height: planImage.ratio && planImage.ratio ? `${calculateHaight(planImage.ratio)}px` : "auto"
						}} />}

					{pinImages && <div className="pins">
						{pinImages.map((u, index) =>
							<img key={index} data-src={!isCalculated ? "" : `${staticServer}/${(selectedType == TypeView.PRINT ? u.convertImageUrl : u.imageUrl)}?${Guid.create()}`} />)}
					</div>}
				</div>
				<div className="content-qr">
					<QRCode value={group.urlQr ?? urlDefault} className="qr" />
					{boxImage && <img data-src={`${staticServer}/${(selectedType == TypeView.PRINT ? boxImage.convertImageUrl : boxImage.path)}?${Guid.create()}`} className="box"
						style={{
							aspectRatio: boxImage.ratio && boxImage.ratio > 0 ? boxImage.ratio.toString() : "auto",
							height: boxImage.ratio && boxImage.ratio ? `${calculateHaight(boxImage.ratio, 54, 72)}px` : "auto"
						}} />}

					{packImage && <img data-src={`${staticServer}/${(selectedType == TypeView.PRINT ? packImage.convertImageUrl : packImage.path)}?${Guid.create()}`} className="pack"
						style={{
							aspectRatio: packImage.ratio && packImage.ratio > 0 ? packImage.ratio.toString() : "auto",
							height: packImage.ratio && packImage.ratio ? `${calculateHaight(packImage.ratio, 54, 72)}px` : "auto"
						}} />}
				</div>
				<div className="content-body">
					<div className="title">{group.name}</div>
					<div className="description">
						<ul>
							{(group.description ?? "")
								.replaceAll("•", "")
								.split("\n")
								.map(u => u.trim())
								.filter(u => u.length > 0)
								.map((u, index) => <li key={index}>{u}</li>)}
						</ul>
					</div>
					{createProductTable(attributes, group)}
				</div>
			</div>
			{typeTemplate != TypeTemplate.TOOLSET_BIG && createProductToolsetTables(attributes, signs, pictograms, group)}
		</div>
	</>)
}

function createProductTable(attributes: IGuideAttributeForPdf[], group: IGuideGroupForPdf) {
	if (!group.products)
		return (<></>);

	const headers: IGuideAttributeForPdf[] = [];

	const isExcludeGroup = excludeGroupsForAttributes.includes(group.id)

	for (let i = 0; i < group.attributes.length; i++) {
		if (group.attributes[i] == Constants.SortAttributeId)
			continue;

		const attribute = attributes.find(x => x.id == group.attributes[i]);
		if (!attribute)
			continue;

		if (isExcludeGroup && excludeGroupAttributes.includes(group.attributes[i]))
			continue;

		headers.push(attribute)
	}
	const indexComplex = headers.findIndex(u => u.id == Constants.AttributeComplexId)

	let cssGridRow = "auto ";

	if (indexComplex > -1) {
		if (indexComplex > 0)
			cssGridRow += `repeat(${indexComplex}, min-content) `;
		cssGridRow += "1fr ";

		if (indexComplex < headers.length - 1)
			cssGridRow += `repeat(${(headers.length - 1 - indexComplex)}, min-content) `;

	}
	else cssGridRow += `repeat(${headers.length}, 1fr)`;

	return (<>
		<div className="products">
			<div className="tbody" style={{ gridTemplateColumns: cssGridRow }}>
				<div className="thead"></div>
				{headers.map((u, index) =>
					<div key={index} className="thead">
						{Constants.AttributeReplaceImages.includes(u.id) ?
							<img data-src={`${staticServer}/Volume/ERP_Data/Attributes_convert/${u.id}.svg?${Guid.create()}`} />
							: u.name}
					</div>
				)}
				{
					group.products.map((u, index) => <>
						<div style={{ fontWeight: "bold" }} key={"attr-val-" + u.id} data-product-id={u.id}>{u.id}</div>
						{
							headers.map((t, index2) => {
								const value = u.attributes.find(f => f.attributeId == t.id)?.value
								if (!value) {
									errors.push(`Не установлено значение атрибута: ${t.name} для продукта: "${u.name}" (${u.id})`)
								}

								return <div key={`${index} ${index2}`}>{value}</div>
							})
						}
					</>)
				}
			</div>
		</div>
	</>);
}

function createProductToolsetTables(
	attributes: IGuideAttributeForPdf[],
	signs: IGuideSignForPdf[],
	pictograms: IGuidePictogramForPdf[],
	group: IGuideGroupForPdf) {
	if (!group.isToolset || !group.toolsetProducts || group.attributes.includes(Constants.AttributeComplexId)) {
		return (<></>);
	}

	const items = Object.keys(group.toolsetProducts).map((u, index) => {

		const name = pictograms.find(t => t.id.toString() === u)?.name;

		return (<>
			<div className="content-toolset-item" key={u}>
				<div className="title">{name}</div>
				{createProductToolsetTable(attributes, signs, group.toolsetProducts[u])}
			</div>
		</>)
	})

	return (<div className="content-toolset">
		{items}
	</div>)
}

function createProductToolsetTable(
	attributes: IGuideAttributeForPdf[],
	signs: IGuideSignForPdf[],
	toolsetProducts: IGuideProductToolsetForPdf[]) {
	return <div className="table">
		{
			toolsetProducts.map((t) => createProductToolsetTableItem(attributes, signs, t))
		}
	</div>
}

function createProductToolsetTableItem(
	attributes: IGuideAttributeForPdf[],
	signs: IGuideSignForPdf[],
	item: IGuideProductToolsetForPdf) {
	const attribute = attributes.find(f => f.id == item.mainAttributeId);
	const seriesItem = signs.find(f => f.id == item.signId);

	if (!seriesItem)
		errors.push(`Не установлен значок группы у продукта: "${item.name}" (${item.id})`)

	return (<>
		<div>
			{seriesItem && <img data-src={`${staticServer}/${seriesItem.convertImageUrl}`} />}
		</div>
		<div>{item.id}</div>
		<div>{item.name}</div>
		<div>{item.attributes.join("; ")} {attribute?.unit}</div>
	</>)
}